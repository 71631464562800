import * as React from "react"

import MainLayout from "../components/mainLayout/MainLayout"
import { GatsbySeo } from "gatsby-plugin-next-seo";

const NotFoundPage = () => (
  <MainLayout>
    <GatsbySeo noindex={true} nofollow={true}/>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </MainLayout>
)

export default NotFoundPage
